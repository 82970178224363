<!-- 主页面-顶部 -->
<template>
    <div class="wrap-login">
        <div class="wrap-login-content">
            <!-- <div class="wrap-login-left"></div> -->
            <div class="wrap-login-right">
                <!-- 登录信息 -->
                <div v-if="isLogin" class="wrap-login-logined">
                    <div @click="_jump('userInfo')" class="login-info">
                        <!-- 账号： -->
                        <span>{{$t('homePageIndex.text14')}}：</span>
                        <span class="name">{{ userData.username }}</span>
                    </div>
                    <div class="login-info">
                        <!-- 余额： -->
                        <span>{{$t('homePageIndex.text19')}}：</span>
                        <span class="name">{{ balance }}</span>
                        <i class="refresh-btn refresh-btn-small" :class="{'rotate': rotate}" @click="getPlatformBalance(true)"></i>
                    </div>
                    <div class="login-info">
                        <ul class="other">
                            <li @click="_jump('userInfo')">{{$t('homePageIndex.text20')}}</li>
                            <li  @click="_jump('deposit')">{{$t('homePageIndex.text21')}}</li>
                            <li  @click="_jump('withdraw')">{{$t('homePageIndex.text22')}}</li>
                            <li  @click="oneKeyBalance">{{$t('homePageIndex.text23')}}</li>
                            <li  @click="_jump('notice')">{{$t('homePageIndex.text24')}}<span v-if="systemNoticeUnread" class="Notice"></span></li>
                        </ul>
                    </div>
                    <div class="loginout" @click="loginOut">{{$t('homePageIndex.text25')}}</div>
                </div>
                <Login v-if="!isLogin" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { _jump } from '@/core/utils/utils'
import { _loginOut } from '@/core/comp/common'
import Login from '@/components/Login.vue'
import { _noticeList } from '@/core/api/modules/system'
import { _getPlatformBalance, _oneKeyBalance } from '@/core/api/modules/user'
import { Message } from 'element-ui'
export default {
    components: {
        Login,
    },
    computed: {
        ...mapGetters([
            'isLogin',
            'userData',
            'systemNoticeUnread', // 未读通知
        ]),
        balance () { // 主账户数据
            return this.userData._totalBalance || '0.00'
        },
    },
    data () {
        return{
            rotate:false,
        }
    },
    methods: {
        _jump,
        //归集
        // 获取各平台余额
        getPlatformBalance (isRefresh) {
            _getPlatformBalance().then(res => {
                if (res && isRefresh) return Message.success(this.$t('tipsMessage.text2'))
            })
        },
        // 一键归集
        oneKeyBalance () {
            _oneKeyBalance().then(res => {
                if (res) return this.getPlatformBalance()
            })
        },
        
        loginOut () {
            _loginOut()
            Message.success(this.$t('tipsMessage.text3'))
            setTimeout(() => {
                this.$router.push({ name: 'home' })
            }, 500)
        },
        getPlatformBalance (isRefresh) {
            this.rotate = true
            _getPlatformBalance().then(res => {
                if (res && isRefresh) return Message.success(this.$t('tipsMessage.text2'))
            })
            this.rotate = false
        },
    },
    mounted () {
        setTimeout(() => {
            console.log('----用户信息', this.userData)
            if(!this.isLogin) return
            _noticeList({}) // 获取未读公告
        }, 2000)
        
    }
}
</script>

<style lang="scss" scoped >
$imgUrl: '~@/assets/images/index/';
    .wrap-login {
        width: 100%;
        color: #fff;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #3D3D3D;
        padding: 12px 0;
    }
    .wrap-login-content {
        width: 1200px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .wrap-login-left{
            display: flex;
             justify-content: flex-start;
            align-items: center;
            width:265px;
            height: 30px;
        }
        .wrap-login-right{
           
        }
    }
    .wrap-login-logined {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .login-info{
            display:flex;
            align-items: center;
            margin-right: 5px;
            .name{
                display: inline-block;
                color: #efc77a;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                vertical-align: top;
                max-width: 100px;
            }
            .other{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                li{
                    height: 15px;
                    max-width: 100px;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #ccc;
                    padding: 0 10px;
                    position: relative;
                    cursor: pointer;
                    word-break: break-word;
                    text-align: center;
                    .Notice{
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        top: -4px;
                        right: 7px;
                        background-color: #ff0000;
                    }
                }
            }
        }
        .loginout{
            cursor: pointer;
        }
    }

</style>
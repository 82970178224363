<template>
   <div class="GamePrizePool-bg">
      <div class="GamePrizePool-game">
         <div v-animate-css.hover="'rubberBand'" class="GamePrizePool-title"></div>
         <div class="GamePrizePool-content">
            <!-- 奖池 -->
            <div class="GamePrizePool-pool">
               <div class="GamePrizePool-left">
                  <div class="item">
                     <i class="icon GamePrizePool-icon1"></i>
                     <p>{{$t("homePageIndex.text54")}}</p>
                  </div>
                  <div class="item">
                     <i class="icon GamePrizePool-icon2"></i>
                     <p>{{$t("homePageIndex.text55")}}</p>
                  </div>
                  <div class="item">
                     <i class="icon GamePrizePool-icon3"></i>
                     <p>{{$t("homePageIndex.text56")}}</p>
                  </div>
                  
                  <!-- <div class="item item-btn" @click="goPath('promo')">
                     <span>Aktivitas Populer</span>
                  </div> -->
               </div>
               
                  <Jackpot />
                  <!-- <Jackpot1 /> -->
               <div class="GamePrizePool-left">
                  <!-- <div class="item item-btn" @click="goPath('deposit')">
                     <span>Isi ulang cepat</span>
                  </div> -->
                  <div class="item">
                     <i class="icon GamePrizePool-icon4"></i>
                     <p>{{$t("homePageIndex.text57")}}</p>
                  </div>
                  <div class="item">
                     <i class="icon GamePrizePool-icon5"></i>
                     <p>{{$t("homePageIndex.text58")}}</p>
                  </div>
                  <div class="item">
                     <i class="icon GamePrizePool-icon6"></i>
                     <p>{{$t("homePageIndex.text59")}}</p>
                  </div>
               </div>
            </div>
            <!-- 排行榜 -->
            <div class="Leaderboard" v-if="gameJackpotList.length>0">
               <div class="Leaderboard-Item">
                  <vueSeamless
                        :data="gameJackpotList"
                        :class-option="classOption"
                  >
                     <div v-for="(item, index) in gameJackpotList" :key="index" class="Leaderboard-list">
                        <span>{{ item.gameName }}</span>
                        <span>{{ item.dataList[0].username }}</span>
                        <span><label>{{ item.dataList[0].jackpot }}</label>{{config.unit}}</span>
                     </div>
                  </vueSeamless>
               </div>
               <div class="Leaderboard-Item">
                  <vueSeamless
                        :data="gameJackpotList"
                        :class-option="classOption"
                  >
                     <div v-for="(item, index) in gameJackpotList" :key="index" class="Leaderboard-list">
                        <span>{{ item.gameName }}</span>
                        <span>{{ item.dataList[0].username }}</span>
                        <span><label>{{ item.dataList[0].jackpot }}</label>{{config.unit}}</span>
                     </div>
                  </vueSeamless>
               </div>
               <div class="Leaderboard-Item">
                  <vueSeamless
                        :data="gameJackpotList"
                        :class-option="classOption"
                  >
                     <div v-for="(item, index) in gameJackpotList" :key="index" class="Leaderboard-list">
                        <span>{{ item.gameName }}</span>
                        <span>{{ item.dataList[0].username }}</span>
                        <span><label>{{ item.dataList[0].jackpot }}</label>{{config.unit}}</span>
                     </div>
                  </vueSeamless>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import vueSeamless from 'vue-seamless-scroll'
import Jackpot from './Jackpot.vue'
import { _getJackpotList } from '@/core/api/modules/game'
import { _jump } from '@/core/utils/utils'
import { mapGetters } from 'vuex'
import { Message } from 'element-ui'
import config from '@/core/common/config'
export default {
   data(){
      return{
         config,
      }
   },
   components:{
      vueSeamless,
      Jackpot,
   },
   mounted() {
      _getJackpotList()
   },
   computed: {
      // 弹出滚动滚屏
      classOption() {
         return {
               step: 0.6,
               limitMoveNum: 1
         };
      },
      ...mapGetters([
         'isLogin',
         'gameJackpotList'
      ])
   },
   methods: {
      _jump,
      goPath(path){
         if(path == 'deposit' && !this.isLogin) return Message.error(this.$t("tipsMessage.text1"))
         _jump(path)
      }
   },
}
</script>

<style lang="scss" scoped>
$imgUrl: '~@/assets/images/index/';
.GamePrizePool-bg {
   background-color:#141414;
   .GamePrizePool-game{
      width: 1200px;
      margin: 0 auto;
      padding: 30px 0;
      .GamePrizePool-title{
         height: 27px;
         width: 100%;
      }
      .GamePrizePool-content{
         font-size: 14px;
         color: #fff;
         width: 100%;
         .GamePrizePool-pool{
            display: flex;
            width: 100%;
            margin-top: 30px;
            justify-content: space-around;
            align-items: center;
            .GamePrizePool-left{
               display: flex;
               flex: 1;
               justify-content: space-around;
               align-items: center;
               .item{
                  flex: 1;
                  @for $i from 1 to 7{
                     i.GamePrizePool-icon#{$i}{
                        width: 50px;
                        height: 50px;
                        display:block;
                        margin: 0 auto;
                        background:url(#{$imgUrl}GamePrizePool-icon#{$i}.png)  no-repeat center/contain;
                     }
                  } 
                  p{
                     text-align: center;
                  }
               }
               .item-btn{
                  max-width: 44px;
                  height: 120px;
                  background:#FFB445;
                  border-radius: 5px;
                  display:flex;
                  justify-content: center;
                  align-items: center;
                  span{
                     width: 14px;
                     font-size: 16px;
                     display: block;
                     word-break: break-word;
                  }
               }
            }
         }
         .Leaderboard{
            display: flex;
            justify-content:space-around;
            align-items: center;
            margin-top: 40px;
            .Leaderboard-Item{
               flex: 1;
               height: 150px;
               overflow: hidden;
               &:nth-child(2){
                  border-right: 1px solid #2B2B2B;
                  border-left: 1px solid #2B2B2B;
                  margin: 0 15px;
                  padding: 0 15px;
               }
               .Leaderboard-list{
                  display: flex;
                  font-size: 14px;
                  color:#959595;
                  margin: 5px auto;
                  justify-content:space-around;
                  align-items: center;
                  span{
                     min-width: 100px;
                  }
                  span:nth-child(2){
                     color:#fff;
                  }
                  span:nth-child(3){
                     text-align: right;
                     color:#F4CB56 ;
                  }
               }
            }
         }
      }
   }
}

</style>
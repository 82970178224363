<template>
   <div class="Suspended" v-if="isSuspended">
      <div class="Suspended-list" @click="_jump('promo')">
         <i class="sidebar-icon sidebar-icon1" @click="isSuspended = false"></i>
      </div>
   </div>
</template>

<script>
import { _jump } from '@/core/utils/utils'
import { Popover } from 'element-ui'
export default {
   components:{
      Popover,
   },
   data () {
      return {
          isSuspended:true,
      }
   },
   methods: {
      _jump,
   }
}
</script>

<style lang="scss" scoped>
$imgUrl: '~@/assets/images/index/';
.Suspended{
   position:fixed;
   left: 0;
   bottom: 0;
   z-index:11;
   .Suspended-list{
      width: 137px;
      height: 189px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 5px;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      background: url(#{$imgUrl}home/Suspended-bg.png)no-repeat center/contain;
      .sidebar-icon{
         width: 24px;
         height:24px;
         display:block;
         position: absolute;
         top: 2px;
         right: 8px;
      }
   }
}
</style>
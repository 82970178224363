<template>
   <div class="LeftSidebar">
      <div class="service-list">
         <div class="mover_icon"></div>
         <div class="mover_text">Hubungi Kami</div>
         <div class="list-icon">
            <!-- <div click="onlink(0)" class="sidebar-icon sidebar-icon0"></div> -->
            <div @click="onlink(1)" class="sidebar-icon sidebar-icon1"></div>
            <div @click="onlink(2)" class="sidebar-icon sidebar-icon2"></div>
            <div @click="onlink(3)" class="sidebar-icon sidebar-icon3"></div>
         </div>
      </div>
   </div>
</template>

<script>
import { _toCustomerService } from '@/core/comp/common'
import { _jump, _createQRcode, _downloadApp } from '@/core/utils/utils'
import { Popover } from 'element-ui'
export default {
   components:{
      Popover,
   },
   data () {
      return {
         appUrl: _downloadApp(),
      }
   },

  created() {
    setTimeout(() => {
      _createQRcode({
        id: 'qrcodeApp1',
        content: this.appUrl,
        width: 130,
        height: 130,
      })
    }, 500);
  },
   methods: {
      _jump,
      _toCustomerService,
      toAgent(){
         this.$router.push({
            name: 'help',
            query: {id:7}
         })
      },
      onlink(id){
         let url = ''
         switch(id){
            case 0:
               url = ''
               break;
            case 1:// whatsapp
               url = 'https://wa.me/62895398826020'
               break;
            case 2:
               return _toCustomerService()
            case 3://telegram
               url = 'https://t.me/jagomerahcs1'
               break;
         }
         url && window.open(url,"_blank")
      }
   }
}
</script>

<style lang="scss" scoped>
$imgUrl: '~@/assets/images/index/';
.LeftSidebar{
   position:fixed;
   right: 0;
   top: 21%;
   // transform:translateY(-50%);
   z-index:11;
   background:#fff;
   border-bottom-left-radius: 100px;  
   .service-list{
      width: 60px;
      height: auto;
      max-height: 250px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 5px;
      padding: 10px 5px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      &:hover{
         .list-icon{
            display: block;
         }
         .mover_text{
            display:none;
         }
      }
      .mover_icon{
         width: 24px;
         height:24px;
         display:block;
         background:url(#{$imgUrl}sidebar/mover-icon.png) no-repeat center;
      }
      .mover_text{
         text-align: center;
         display: flex;
         align-items: center;
         -ms-writing-mode: tb-rl;
         writing-mode: vertical-rl;
         transform: rotate(180deg);
         margin: 10px 0;
      }
      .list-icon{
         display: none;
         margin: 20px 0 30px;
         .sidebar-icon{
            margin: 10px 0;
         }
      }
      .sidebar-icon{
         width: 30px;
         height:30px;
         margin: 10px 0;
         display:block;
      }
      .LeftSidebar-text{
         width: 100%;
         text-align:center;
         font-size: 12px;
      }
      @for $i from 0 to 4{
         .sidebar-icon#{$i}{
            background:url(#{$imgUrl}sidebar/icon#{$i}.png) no-repeat center/contain;
         }
      }
   }
}
</style>
<style lang="scss">
.qrCodeApp{
   .qrText{
      text-align:center;
      margin-top:10px;
      font-size: 16px;
      color: #cdb659;
   }
}
</style>

import comp_en from '../../core/il8n/lang/en';
const en = {
    name: "data",
    // 提示
    tipsMessage: {
        text1: "Please login first",
        text2: "Refresh successfully",
        text3: "Logged out",
    },
    // Home index
    homePageIndex: {
        text1: "INDONESIA TIME",
        text2: "Continue the classic, create luxury",
        text3: "Home",
        text4: "Enter the game",
        text5: "APP",
        text6: "Promotions",
        text7: "SLOTS",
        text8: "CASINO",
        text9: "E-Sports",
        text10: "SPORTS",
        text11: "Lottery",
        text12: "CARD",
        text13: "Fishing",
        text14: "Account",
        text15: "Password",
        text16: "Forget",
        text17: "Login",
        text18: "Register",
        text19: "Balance",
        text20: "Member Center",
        text21: "Online Deposit",
        text22: "Online Withdrawal",
        text23: "One-click Homecoming",
        text24: "Unread message",
        text25: "Logout",
        text26: "About Us",
        text27: "Deposit Help",
        text28: "Withdrawal help",
        text29: "Rules and Statements",
        text30: "FAQ",
        text31: "Agent Center",
        text32: "Service Advantage",
        text33: "Deposit arrives quickly",
        text34: "Average Time",
        text35: "Seconds",
        text36: "Withdraw money to account quickly",
        text37: "Average Time",
        text38: "Product Advantage",
        text39: "Safe and Reliable",
        text40: "Monthly commission on time, zero risk, zero risk, high return customer funds are the most complete protection, no worries!",
        text41: "Funded",
        text42: "Users can withdraw quickly, the amount is not capped, the platform is stable and strong, and the funds are guaranteed.",
        text43: "More Services",
        text44: "Promotions continue",
        text45: "Large discounts have been launched throughout the year to meet the needs of various types of players.",
        text46: "Comprehensive Data Services",
        text47: "Data overall planning, endless information, all kinds of betting/funding data. Platform data at a glance.",
        text48: "Handheld entertainment, anytime, anywhere, unlimited play",
        text49: "The mobile APP is perfectly online, bringing together the world's top platforms without conversion. It provides nearly 10,000 electronic games, live video, lottery games, chess and card battles, and fishing sports competitions. It supports one-key deposit/withdrawal of mobile phones, which is convenient and fast. Wealth is in your hands!",
        text50: "Scan the code to download the APP",
        text51: "Support iOS & Android all mobile devices",
        text52: "No need to download, just enter the URL on the phone to access",
        text53: "Recommended popular games",
        text54: "Rebate rebate",
        text55: "Daily Check-In",
        text56: "Cooperative Agent",
        text57: "Graypay",
        text58: "Googlepay",
        text59: "GCash",
        text60: "WeChat",
        text61: "Bank Card",
        text62: "More",
        text63: "The platform uses industry-leading technology to perfectly reflect the vivid and smooth graphics of the game, with the characteristics of high interest, strong entertainment, and rich profits.",
        text64: "Customer service",
        text65: "Grand Prize Game",
        text66: "APP download",
        text67: "Proxy Registration",
        text68: "latest news",
        text69: "About Us",
        text70: "Deposit Help",
        text71: "Withdrawal help",
        text72: "Rules and Statements",
        text73: "FAQ",
        text74: "Agent to join",
        text75: "Proxy login",
        text76: "Proxy Registration",
    },
    // 活动
    promoInfo:{
        text1: "All Offers",
        text2: "Long-term activity",
        text3: "Limited time event",
        text4: "There is currently no activity",
        text5: "to",
        text6: "Contact Customer Service",
        text7: "Join Now",
        text8: "Active status:",
        text9: "View activity log",
        text10: "Activity Status: Activity Deadline",
        text11: "View activity log",
        text12: "Activity record",
        text13: "Date",
        text14: "Complete condition",
        text15: "Completed Status",
    },
    // 游戏二级页面
    gamePage: {
        text0: 'All games',
        text1: "Play now",
        text2: "Top Recommendations",
        text3: "Play now",
        text4: "Please enter the game name",
        text5: "Enter the game",
        text6: "League of Legends",
        text7: "Honor of Kings",
        text8: "DOTA2",
        text9: "CSGO",
        text10: "Hearthstone",
        text11: "Overwatch",
        text12: "FIFA",
        text13: "High-definition live broadcast allows you to experience exciting sports",
        text14: "Full coverage of the world's top events, providing professional odds data and settlement result data. It has the top",
        text15: "Technical support to provide players with an amazing visual interface and the ultimate user experience.",
        text16: "Sexy Dealer · Showcase",
        text17: "The most beautiful croupier on the entire network, starring beauties from seven countries, original entertainment & professional dual mode, extreme high-definition",
        text18: "Visual feast, diversified game types, bring you the most comfortable gaming experience.",
        text19: "The latest and most dazzling live online entertainment, you can choose from classics, microphone cards, and package table baccarat, and beautiful women such as",
        text20: "Compatible with multiple cloud terminals, bringing you the ultimate smooth gaming experience.",
        text21: "Baccarat",
        text22: "Roulette",
        text23: "Sic Bo",
        text24: "Dragon Tiger",
        text25: "Niu Niu",
        text26: "Fried Golden Flower",
        text27: "Three Dukes",
        text28: "Experience the thrill of minutes",
        text29: "Top flagship platform, the most popular color varieties, diverse gameplay and simple operation",
        text30: "Real-time help, keep adding good luck!",
        text31: "Enjoy the wonderful world",
        text32: "Super-high odds, all kinds of lottery, Mark Six, and Beijing racing",
        text33: "Committed to creating a high-quality entertainment environment for players.",
        text34: "Speed ​​Racing",
        text35: "Beijing Racing",
        text36: "Shi Shi Cai",
        text37: "Five from Eleven",
        text38: "Mark Six",
        text39: "New gameplay · Feel the excitement",
        text40: "A variety of sports events are available, and the game has a variety of gameplay. The second half is bold and the ball is new to play",
        text41: "Fa, feel different and wonderful",
        text42: "Cash Early · Stop Loss in Time",
        text43: "Innovative functions are cashed in advance, you can settle in advance without waiting, and the stop-loss mechanism allows you to have no taboos",
        text44: "Football",
        text45: "Basketball",
        text46: "Volleyball",
        text47: "Baseball",
        text48: "Tennis",
        text49: "Ping-Pong",
        text50: "Badminton",
    },
    // 用户中心
    userPage:{
        // 活动记录
        text1: "Activity record",
        text2: "The data of each activity will have a certain time delay, for reference only",
        text3: "Event Name",
        text4: "activity time",
        text5: "Year/Month/Day",
        text6: "Query",
        text7: "Reset",
        text8: "Event Name",
        text9: "Participation time",
        text10: "Operation",
        text11: "View Details",
        text12: "Not a single record...",
        text13: "Please enter the event name",
        // 收款方式管理
        text14: "Management of payment methods",
        text15: "Add payment method",
        text16: "Account Name",
        text17: "Bank Name",
        text18: "Select Bank",
        text19: "Branch name",
        text20: "Please enter the branch name",
        text21: "Bank Card Number",
        text22: "Please enter the bank card number",
        text23: "Confirm card number",
        text24: "Please enter the bank card number again",
        text25: "Mobile phone number",
        text26: "Verification Code",
        text27: "Please enter the verification code",
        text28: "Send verification code",
        text29: "Account Name",
        text30: "currency name",
        text31: "Link",
        text32: "Please enter ERC20 or TRC20",
        text33: "Wallet address",
        text34: "Please enter the wallet address",
        text35: "Confirm address",
        text36: "Please enter the wallet address again",
        text37: "Mobile phone number",
        text38: "Currency",
        text39: "Select currency",
        text40: "Wallet Account ID",
        text41: "Please enter origo wallet ID",
        text42: "Confirm account",
        text43: "Please enter the origo wallet ID again",
        text44: "OK",
        text45: "Return",
        text46: "Bank Card",
        text47: "Digital Currency",
        text48: "origo wallet",
        text49: "No bank card yet",
        text50: "Add bank card",
        text51: "Add digital currency",
        text52: "Add origo wallet",
        // 公告
        text53: "Notification",
        text54: "Announcement",
        // 银行卡
        text55: "Payment by bank card",
        text56: "Payment Details",
        text57: "Payee account name",
        text58: "Receiving Bank",
        text59: "Payment card number",
        text60: "Account opening bank-sub-branch information",
        text61: "Warm reminder",
        text62: "1. Please fill in the card number and Deposit Amount correctly.",
        text63: "2. Please confirm that the payment has been made after the bank card transfer is successful.",
        text64: "3. The arrival time depends on the bank's processing time, please be patient.",
        text65: "4. Please do not pay twice.",
        // 存款
        text66: "Deposit",
        text67: "Payment method",
        text68: "Company Deposit",
        text69: "Online Deposit",
        text70: "Digital Currency",
        text71: "VIP Exclusive",
        text72: "Gift",
        text73: "Recharge Channel",
        text74: "Warm reminder",
        text75: "1. Please pay attention to the minimum transfer amount displayed on the page, recharges less than the minimum Deposit Amount will not be credited and cannot be refunded",
        text76: "2. After you recharge to the above address, you need the confirmation of the entire network node, please wait patiently, we will credit the corresponding amount to your account immediately after receiving the digital currency. If you haven't received it for a long time, you can contact customer service consult",
        text77: "3. Please be sure to check the currency and chain name when transferring money. If you submit the wrong asset, you cannot get it back",
        text78: "4. The exchange rate displayed on the page is for reference only. Due to fluctuations in the exchange rate, the account will be calculated based on the latest exchange rate ratio",
        text79: "Deposit Name",
        text80: "The account has not arrived in time, please be sure to enter the correct deposit name",
        text81: "Deposit Amount",
        text82: "Single minimum deposit amount",
        text83: "Single maximum deposit amount",
        text84: "Please enter deposit name",
        text85: "Please enter a legal amount",
        text86: "The Deposit Amount is not within the range",
        text87: "The channel is abnormal, please contact customer service!",
        //短信验证弹窗
        text88: "Please verify your mobile phone number to withdraw money",
        text89: "Mobile",
        text90: "Please enter your phone number",
        text91: "Verification Code",
        text92: "Please enter the verification code",
        text93: "Send verification code",
        text94: "OK",
        text95: "Mobile phone number cannot be empty",
        text96: "Please enter the correct mobile phone number",
        text97: "The verification code cannot be empty",
        text98: "The format of the phone number is incorrect",
        // 取款
        text99: "Withdrawal",
        text100: "Main account limit",
        text101: "yuan",
        text102: "One-click collection",
        text103: "Inclusion",
        text104: "Payment method",
        text105: "Add payment method",
        text106: "Next step",
        text107: "Payment method",
        text108: "Withdrawal Amount",
        text109: "Current withdrawal amount",
        text110: "All",
        text111: "Actual Receipt Amount",
        text112: "Actual Receipt Amount",
        text113: "Withdrawal Fee",
        text114: "Administrative Expenses",
        text115: "Discount deduction",
        text116: "Transaction Password",
        text117: "Please enter your password",
        text118: "Previous step",
        text119: "OK",
        text120: "Please select a withdrawal method",
        text121: "Refresh succeeded",
        text122: "The withdrawal amount cannot be empty!",
        text123: "The transaction password cannot be empty!",
        text124: "The application is successful, please wait for the platform to review!",
        // 游戏记录
        text125: "Game Record",
        text126: "There will be a certain time delay in the data of each bet, for reference only",
        text127: "Game Platform",
        text128: "Betting Amount",
        text129: "Time Filter",
        text130: "Query",
        text131: "Reset",
        text132: "Order number",
        text133: "Game Platform",
        text134: "Game Name",
        text135: "Bet time",
        text136: "Payout Time",
        text137: "Betting Amount",
        text138: "Valid Bet",
        text139: "Payout Amount",
        text140: "Win and loss Amount",
        text141: "Total Bet",
        text142: "Total Effective Bet",
        text143: "Total payout",
        text144: "Total win and loss amount",
        // 彩金
        text146: "Bonus",
        text147: "Collection period expires",
        text148: "Loading",
        text149: "In the end",
        text150: "No data yet",
        text151: "Bonus claim",
        text152: "Flow Requirements",
        text153: "fold",
        text154: "Cancel",
        text155: "Get it now",
        text156: "All",
        text157: "Available",
        text158: "Claimed",
        text159: "Expired",
        // 公告消息
        text160: "Announcement message",
        text161: "Not a single notification...",
        text162: "Not a single announcement...",
        // 全民返利
        text163: "National Rebate",
        text164: "Rule Description",
        text165: "Cumulative rebate",
        text166: "full",
        text167: "and valid membership≥",
        text168: "People can get it",
        text169: "Receive",
        text170: "Members (total number of members",
        text171: "Valid Member",
        text172: "My Promotion Code",
        text173: "Promotion Code",
        text174: "My invitation code",
        text175: "invitation code",
        text176: "My promotion address",
        text177: "Copy address",
        text178: "Rule Description",
        text179: "Receive rebate",
        text180: "Flow Requirements",
        text181: "Cancel",
        text182: "OK",
        text183: "The claim requirement is not met, the minimum claim amount",
        text184: "the number of valid members≥",
        text185: "People, get the upper limit",
        text186: "Received successfully",
        text187: "Failed to claim",
        // 邀请会员
        text188: "Invite members",
        text189: "Total Bet",
        text190: "Accumulated total rebates",
        text191: "Registration time:",
        text192: "Select time:",
        text193: "Member Account",
        text194: "Registration time",
        text195: "Total effective bets",
        text196: "Rebate",
        // 个人资料
        text197: "Profile",
        text198: "Common Device Management",
        text199: "(Up to 10 common login devices can be bound)",
        text200: "Native",
        text201: "Delete",
        text202: "No device currently",
        // 资金密码
        text203: "Funding Password",
        text204: "Old password",
        text205: "Please enter the original fund password",
        text206: "The current password of funds you are using",
        text207: "New password",
        text208: "Please enter the new fund password",
        text209: "Password 6-digit combination",
        text210: "Confirm Password",
        text211: "Please enter the password again",
        text212: "must match the new password",
        text213: "OK",
        text214: "Confirm password cannot be empty",
        text215: "Transaction password must be 6 digits",
        text216: "The passwords entered twice do not match",
        text217: "New fund password cannot be empty",
        text218: "Transaction password must be 6 digits",
        text219: "The original fund password cannot be empty",
        // 账号密码
        text220: "Account Password",
        text221: "Old password",
        text222: "Please enter the original login password",
        text223: "The current login password you are using",
        text224: "New password",
        text225: "Please enter a new login password",
        text226: "Password 6-15 digits, at least two combinations of alphanumeric symbols",
        text227: "Confirm Password",
        text228: "Please enter the new password again",
        text229: "must match the new password",
        text230: "Confirm password cannot be empty",
        text231: "The passwords entered twice do not match",
        text232: "The original login password cannot be empty",
        text233: "The new login password cannot be empty",
        text234: "Please login again",
        // tab
        text235: "Security Authentication",
        text236: "Funding Password",
        text237: "Login Password",
        text238: "Device Management",
        // 返水详情页
        text239: "Transaction History",
        text240: "Rebate Details",
        text241: "Only the transaction records of the past month can be inquired. For more information, please contact customer service for inquiries",
        text242: "Betting Amount",
        text243: "Time Filter",
        text244: "Time",
        text245: "Game Platform",
        text246: "Game Name",
        text247: "Flowing Water",
        text248: "Rebate",
        text249: "Your query time has exceeded the established time limit, if you need more information, please contact online customer service",
        // 交易记录
        text250: "Transaction History",
        text251: "Only the transaction records of the past month can be inquired. For more information, please contact customer service to inquire",
        text252: "Transaction Type",
        text253: "Transaction time",
        text254: "Select time",
        text255: "Copy",
        text256: "Amount",
        text257: "Status",
        text258: "Details",
        text259: "Recharge Record",
        text260: "Bank Transfer",
        text261: "Withdrawal History",
        text262: "Rebate record",
        text263: "Other",
        text264: "Today",
        text265: "Yesterday",
        text266: "Last 7 days",
        text267: "Last 30 days",
        text268: "Payment method",
        text269: "Withdrawal method",
        text270: "Rebate type",
        text271: "Type",
        text272: "Your query time has exceeded the established time limit, if you need more information, please contact online customer service",
        // 收款方式管理
        text273: "Management of payment methods",
        text274: "Add bank card/digital currency",
        text275: "Do not add the same bank repeatedly",
        // 基本信息
        text276: "Account Password",
        text277: "Transaction Password",
        text278: "Manage Devices",
        text279: "Management",
        text280: "Modify",
        text281: "Settings",
        // 信息编辑
        text282: "Real name",
        text283: "Please enter your real name",
        text284: "Member's Birthday",
        text285: "User Nickname",
        text286: "Contact QQ",
        text287: "Please enter user nickname",
        text288: "Mobile number",
        text289: "Please enter your mobile number",
        text290: "Contact WeChat",
        text291: "Please enter contact WeChat",
        text292: "Member Email",
        text293: "Please enter member email address",
        text294: "Please enter user nickname",
        // 个人中心
        text295: "Ordinary user",
        text296: "Balance",
        text297: "Commission",
        text298: "Rebate Rebate",
        text299: "VIP level",
        text300: "Level",
        text301: "Condition met",
        text302: "Time",
        // 用户菜单
        text303: "Profile",
        text304: "Game Record",
        text305: "Transaction History",
        text306: "Announcement message",
        text307: "Management of payment methods",
        text308: "Activity Log",
        text309: "National Rebate",
        text310: "Deposit",
        text311: "Withdrawal",
        text312: "Bonus",
        text313: "The rebate amount does not meet the conditions and cannot be claimed!",
        text314: "Please enter a minimum amount",
        text315: "The bonus has been claimed",
        text316: "Expired and cannot be claimed",
    },
    ...comp_en,
};
export default en
<!-- 登录 -->
<template>
    <div class="log-container">
        <!-- 账号 -->
        <div class="log-row account-row">
            <input class="user" v-model="form.account" type="text" :placeholder="$t('homePageIndex.text14')" />
        </div>
        <!-- 密码 -->
        <div class="log-row password-row">
            <input v-model="form.password" type="password" :placeholder="$t('homePageIndex.text15')" />
            <span @click="forget">{{$t('homePageIndex.text16')}}？</span>
        </div>
        <!-- 验证码 -->
        <div class="loginInput-row">
            <VerifyCode ref="code" />
        </div>
        <!-- 按钮 -->
        <div class="loginbtn-row">
            <!-- 登录 -->
            <div class="btn btn-login" @click="login">{{$t('homePageIndex.text17')}}</div>
            <!-- 注册 -->
            <div class="btn btn-register" @click="register">{{$t('homePageIndex.text18')}}</div>
        </div>
        <!-- 注册 -->
        <Register :showRegister="showRegister" @closeDialog="closeDialog"/>
        <!-- 忘记密码 -->
        <ForgetPassword :ForgetDialog="ForgetDialog" @closeForget="closeForget"  />
        <!-- ip、设备验证 -->
        <LoginAction 
            :ipDialog="ipDialog"
            :form="form"
            :codeDom="codeDom"
            :deviceDialog="deviceDialog"
            @closeDevice="closeDevice"
            @closeipDialog="closeipDialog"/>
    </div>
</template>

<script>
import VerifyCode from '@/core/components/VerifyCode.vue'
import { _loginMethod } from '@/core/comp/pages/login.js'
import Register from '@/core/components/RegisterForm.vue'
import { _noticeList } from '@/core/api/modules/system'
import ForgetPassword from '@/core/components/ForgetPassword.vue'
import LoginAction from '@/core/components/LoginAction.vue'

export default {
    components: {
        VerifyCode,
        Register,
        ForgetPassword,
        LoginAction,
    },
    data () {
        return {
            form: {
                account: '', // 账号
                password: '', // 密码
            },
            codeDom:{},
            showRegister:false,// 注册
            ForgetDialog:false,// 忘记密码弹窗开关
            ipDialog: false, // ip验证弹窗开关
            deviceDialog: false, // 设备验证弹窗
        }
    },
    methods: {
        //忘记密码
        forget(){
            this.ForgetDialog = true
        },
        login () {
            _loginMethod(this.form, this.$refs.code, () => {}, err => {
                this.codeDom = this.$refs.code
                console.log(this.codeDom,"this.codeDom")
                switch (err) {
                    case 'ip': // ip验证
                        this.ipDialog = true
                        break
                    case 'device': // 设备验证
                        this.deviceDialog = true
                        break
                }
            })
        },
        //注册
        register () {
            // this.$router.push({ name: 'register' })
            this.showRegister = true
        },
        // 关闭注册
        closeDialog() {
            this.showRegister = false
        },
        // 关闭忘记密码
        closeForget(){
            this.ForgetDialog = false
        },
        
        // 关闭ip验证
        closeipDialog(){
            this.ipDialog = false
        },
        
        // 关闭设备验证
        closeDevice(){
            this.deviceDialog = false
        },
        
    }
}
</script>

<style scoped lang="scss">
$imgUrl: '~@/assets/images/index/';
$names:account,password;
.log-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .log-row{
        width: 160px;
        height: 30px;
        position: relative;
        display: flex;
        align-items: center;
        border:1px solid #878787;
        border-radius: 6px;
        overflow: hidden;
        padding: 2px 0px 2px 30px;
        margin-right: 10px;
        background: #fff;
        input{
            width: 100%;
            height: 100%;
            border:none;
            color: #000;
            background:transparent;
        }
        span{
            display:inline-block;
            font-size: 14px;
            word-break: keep-all;
            color: #E10B0C;
            &:hover{
                color: #D6BD7B;
            }
        }
    }
    @each $name in $names{
        .#{$name}-row{
            &::before{
                content: "";
                width: 12px;
                height: 14px;
                position: absolute;
                left: 8px;
                top: 6px;
                background:url(#{$imgUrl}#{$name}-icon.png)no-repeat center/contain;
            }
        }
    }
    .password-row{
        justify-content:space-between;
    }
    .loginInput-row{
        border:none;
        width: 240px;
        height: 30px;
        margin-right: 10px;
    }
    .loginbtn-row{
        display:flex;
        .btn{
            width:93px;
            height:30px;
            font-size: 14px;
            display:flex;
            align-items:center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
            font-weight: bold;
            background: linear-gradient(180deg, #FF0000 0%, #C10000 100%);
            border-radius: 3px;
        }
        .btn.btn-register{
            color: #FF0000;
            background: linear-gradient(180deg, #FFF701 8.85%, #FAF681 67.71%, #FEB700 100%);
        }
    }
}
</style>
<!-- 主容器 -->
<template>
    <div class="Wrap-container">
        <div class="WrapHeader">
            <WrapHeader @scrollToCode="scrollToCode"/>
            <WrapMenu @scrollToCode="scrollToCode"/>
            <WrapLogin />
        </div>
        <keep-alive :include="keepAliveRouters">
            <router-view />
        </keep-alive>
        <!-- 侧边栏 -->
        <LeftSidebar />
        <!-- 底部浮窗 -->
        <Suspended />
        <WrapBottom />
    </div>
</template>

<script>
import WrapHeader from '../components/wrap-header/WrapHeader.vue'
import WrapLogin from '../components/wrap-login/WrapLogin.vue'
import WrapMenu from '../components/wrapMenu/WrapMenu.vue'
import WrapBottom from '../components/wrap-bottom/WrapBottom.vue'
import LeftSidebar from '../components/left-sidebar/LeftSidebar.vue'
import Suspended from '../components/suspended/Suspended.vue'
export default {
    components: {
        WrapHeader,
        WrapLogin,
        LeftSidebar,
        WrapBottom,
        WrapMenu,
        Suspended
    },
    data () {
        return {
            // 不用销毁的页面
            keepAliveRouters: [
                'promo',
            ]
        }
    },
    watch: {
        '$route.query'(val){
            if(val.scroll){
                this.scrollToCode()
            }else{
                document.documentElement.scrollTop = 0;
            }
        },
        $i18n:{
            handler(val) {
                document.documentElement.lang = val.locale
                // this.$root.$i18n.locale = val.locale
            },
            immediate: true
        },
        
            
    },
    mounted(){
        // document.documentElement.lang = this.$i18n.locale
    },
    methods:{
        scrollToCode(){
            setTimeout(() => {
                document.documentElement.scrollTop = 3000;
            },100)
        },
    },
}
</script>
<style scoped lang="scss">
.Wrap-container{
    position: relative;
    .WrapHeader{
        position:sticky;
        top: 0;
        left: 0;
        z-index: 11;
    }
}
    
</style>
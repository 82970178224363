import comp_zh_CN from '../../core/il8n/lang/zh_CN';
const zh_CN = {
    name: "data",
    // 提示
    tipsMessage: {
        text1: "请先登录",
        text2: "刷新成功",
        text3: "已登出",
    },
    // 首页index
    homePageIndex: {
        text1: "INDONESIA TIME",
        text2: "延续经典,缔造奢华",
        text3: "首页",
        text4: "进入游戏",
        text5: "手机投注",
        text6: "优惠活动",
        text7: "电子游艺",
        text8: "真人视讯",
        text9: "电子竞技",
        text10: "体育赛事",
        text11: "彩票游戏",
        text12: "棋牌游戏",
        text13: "捕鱼游戏",
        text14: "账号",
        text15: "密码",
        text16: "忘记",
        text17: "登录",
        text18: "注册",
        text19: "余额",
        text20: "会员中心",
        text21: "线上存款",
        text22: "线上取款",
        text23: "一键归户",
        text24: "未读讯息",
        text25: "登出",
        text26: "关于我们",
        text27: "存款帮助",
        text28: "取款帮助",
        text29: "规则与声明",
        text30: "常见问题",
        text31: "代理中心",
        text32: "服务优势",
        text33: "存款火速到账",
        text34: "平均时间",
        text35: "秒",
        text36: "取款火速到账",
        text37: "平均时间",
        text38: "产品优势",
        text39: "安全可靠",
        text40: "每月准时出佣，零风险，零风险，高回报客户资金得到最完善的保障，无后顾之忧！",
        text41: "资金雄厚",
        text42: "用户提款快速，金额不封顶，平台稳定实力雄厚，资金有保障。",
        text43: "更多服务",
        text44: "优惠不断",
        text45: "全年度不停推出大额度优惠，满足各种类型各种玩家需求。",
        text46: "全面数据服务",
        text47: "数据统筹规划，信息详无不尽,投注/资金各种数据。平台数据一览无余。",
        text48: "掌上娱乐，随时随地，无限畅玩",
        text49: "手机APP完美上线，汇聚全球顶级平台额度无需转换。提供近万款电子游艺、真人视讯、彩票游戏、棋牌对战、捕鱼达人体育竞技。支持手机一键存/取,方便快捷，财富尽在掌握！",
        text50: "扫码下载APP",
        text51: "支持 iOS & Android 全部移动设备",
        text52: "无需下载，手机输入网址即可访问",
        text53: "热门游戏推荐",
        text54: "返点返利",
        text55: "每日签到",
        text56: "合作代理",
        text57: "Graypay",
        text58: "Googlepay",
        text59: "GCash",
        text60: "热门活动",
        text61: "快捷充值",
        text62: "更多",
        text63: "平台使用领先于业界的技术完美地把游戏生动流畅的画面体现出来，拥有趣味性极高、娱乐性强、盈利丰富等特点。",
        text64: "在线客服",
        text65: "大奖游戏",
        text66: "APP下载",
        text67: "代理注册",
        text68: "最新消息",
        text69: "关于我们",
        text70: "存款帮助",
        text71: "取款帮助",
        text72: "规则与声明",
        text73: "常见问题",
        text74: "代理加盟",
        text75: "代理登录",
        text76: "代理注册",
    },
    // 活动
    promoInfo:{
        text1: "全部优惠",
        text2: "长期活动",
        text3: "限时活动",
        text4: "暂无活动",
        text5: "至",
        text6: "联系客服",
        text7: "立即参与",
        text8: "活动状态：",
        text9: "查看活动记录",
        text10: "活动状态：活动截止",
        text11: "查看活动记录",
        text12: "活动记录",
        text13: "日期",
        text14: "完成条件",
        text15: "完成状态",
    },
    // 游戏二级页面
    gamePage: {
        text0:'全部游戏',
        text1: "立即游戏",
        text2: "热门推荐",
        text3: "立即游戏",
        text4: "请输入游戏名称",
        text5: "进入游戏",
        text6: "英雄联盟",
        text7: "王者荣耀",
        text8: "DOTA2",
        text9: "CSGO",
        text10: "炉石传说",
        text11: "守望先锋",
        text12: "国际足联",
        text13: "高清直播让您体验精彩热血竞技",
        text14: "全球顶尖赛事全覆盖，提供专业的赔率数据、结算赛果数据。拥有顶尖",
        text15: "的技术支持，为玩家提供令人惊叹的视觉界面和极致的用户体验。",
        text16: "性感荷官 · 倾情展示",
        text17: "全网最美荷官，七国佳丽倾情出演，独创娱乐&专业双模式，极致高清",
        text18: "视觉盛宴，多元化游戏种类，为您带来最舒心的游戏体验。",
        text19: "最新最炫的真人在线娱乐，经典、咪牌、包桌百家乐随心选择，美女如",
        text20: "云多终端兼容，为您带来极致畅快的游戏体验。",
        text21: "百家乐",
        text22: "轮盘",
        text23: "骰宝",
        text24: "龙虎",
        text25: "牛牛",
        text26: "炸金花",
        text27: "三公",
        text28: "体验分分钟的刺激",
        text29: "顶尖旗舰平台，最全热门彩种，玩法多样操作简洁",
        text30: "实时助运，稳添好彩！",
        text31: "享受时时彩精彩",
        text32: "超高赔率，时时彩、六合彩、北京赛车应有尽有",
        text33: "致力为玩家打造高品质的娱乐环境。",
        text34: "极速赛车",
        text35: "北京赛车",
        text36: "时时彩",
        text37: "十一选五",
        text38: "六合彩",
        text39: "崭新玩法 · 感受精彩",
        text40: "丰富的体育赛事一应俱全，项目玩法多元，下半场波胆、让球崭新玩",
        text41: "法，感受不一样的精彩",
        text42: "提前兑现 · 及时止损",
        text43: "创新功能提前兑现，无需等待即可提前结算，止损机制让您百无禁忌",
        text44: "足球",
        text45: "篮球",
        text46: "排球",
        text47: "棒球",
        text48: "网球",
        text49: "乒乓球",
        text50: "羽毛球",
    },
    // 用户中心
    userPage:{
        // 活动记录
        text1: "活动记录",
        text2: "每条活动的数据将会有一定的时间延迟，仅供参考使用",
        text3: "活动名称",
        text4: "活动时间",
        text5: "年/月/日",
        text6: "查询",
        text7: "重置",
        text8: "活动名称",
        text9: "参与时间",
        text10: "操作",
        text11: "查看详情",
        text12: "一条记录也没有呢...",
        text13: "请输入活动名称",
        // 收款方式管理
        text14: "收款方式管理",
        text15: "添加收款方式",
        text16: "账户姓名",
        text17: "银行名称",
        text18: "选择银行",
        text19: "支行名称",
        text20: "请输入支行名称",
        text21: "银行卡号",
        text22: "请输入银行卡号",
        text23: "确认卡号",
        text24: "请再次输入银行卡号",
        text25: "手机号",
        text26: "验证码",
        text27: "请输入验证码",
        text28: "发送验证码",
        text29: "账户姓名",
        text30: "货币名称",
        text31: "链路",
        text32: "请输入ERC20或TRC20",
        text33: "钱包地址",
        text34: "请输入钱包地址",
        text35: "确认地址",
        text36: "请再次输入钱包地址",
        text37: "手机号",
        text38: "币种",
        text39: "选择币种",
        text40: "钱包账户ID",
        text41: "请输入origo钱包ID",
        text42: "确认账户",
        text43: "请再次输入origo钱包ID",
        text44: "确定",
        text45: "返回",
        text46: "银行卡",
        text47: "数字货币",
        text48: "origo钱包",
        text49: "暂无银行卡",
        text50: "添加银行卡",
        text51: "添加数字货币",
        text52: "添加origo钱包",
        // 公告
        text53: "通知",
        text54: "公告",
        // 银行卡
        text55: "银行卡支付",
        text56: "支付详情",
        text57: "收款户名",
        text58: "收款银行",
        text59: "收款卡号",
        text60: "开户行-支行信息",
        text61: "温馨提示",
        text62: "1、请正确填写收款卡号和充值金额。",
        text63: "2、银行卡转账成功后请确认已付款。",
        text64: "3、到账时间取决于银行处理时间，请耐心等待。",
        text65: "4、请勿重复支付。",
        // 存款
        text66: "存款",
        text67: "支付方式",
        text68: "公司入款",
        text69: "线上入款",
        text70: "数字货币",
        text71: "VIP专享",
        text72: "赠送",
        text73: "充值通道",
        text74: "温馨提示",
        text75: "1.请注意页面显示的最小转账额度，小于最小充值金额的充值将不会上账且无法退回",
        text76: "2.您充值至上述地址后，需要整个网络节点的确认，请耐心等待，我们收到数字货币后会立即向您的账户上账对应金额，若长时间未收到，可联系客服咨询",
        text77: "3.转账时请务必核对币种以及链名称，若提交错误资产不可找回",
        text78: "4.页面显示汇率只供参考，由于汇率存在波动，上账时将根据最新的汇率比例进行计算",
        text79: "存款姓名",
        text80: "未及时到账，请务必输入正确的存款姓名",
        text81: "充值金额",
        text82: "单笔最低入款金额",
        text83: "单笔最高入款金额",
        text84: "请输入存款姓名",
        text85: "请输入合法金额",
        text86: "充值金额不在范围内",
        text87: "通道异常，请联系客服！",
        //短信验证弹窗
        text88: "请验证手机号提款",
        text89: "手机",
        text90: "请输入手机号",
        text91: "验证码",
        text92: "请输入验证码",
        text93: "发送验证码",
        text94: "确定",
        text95: "手机号不能为空",
        text96: "请输入正确的手机号",
        text97: "验证码不能为空",
        text98: "手机号格式错误",
        // 取款
        text99: "取款",
        text100: "主账户额度",
        text101: "元",
        text102: "一键归集",
        text103: "归集中",
        text104: "收款方式",
        text105: "添加收款方式",
        text106: "下一步",
        text107: "收款方式",
        text108: "提款金额",
        text109: "当前可提现金额",
        text110: "全部",
        text111: "实际到账金额",
        text112: "实际到账金额",
        text113: "提现手续费",
        text114: "行政费用",
        text115: "优惠扣除",
        text116: "交易密码",
        text117: "请输入密码",
        text118: "上一步",
        text119: "确定",
        text120: "请选择取款方式",
        text121: "刷新成功",
        text122: "提款金额不能为空！",
        text123: "交易密码不能为空！",
        text124: "申请成功，请等待平台审核!",
        // 游戏记录
        text125: "游戏记录",
        text126: "每条注单的数据将会有一定的时间延迟，仅供参考使用",
        text127: "游戏平台",
        text128: "投注金额",
        text129: "时间筛选",
        text130: "查询",
        text131: "重置",
        text132: "订单号",
        text133: "游戏平台",
        text134: "游戏名称",
        text135: "投注时间",
        text136: "派彩时间",
        text137: "投注金额",
        text138: "有效投注",
        text139: "派彩金额",
        text140: "盈亏金额",
        text141: "总投注",
        text142: "总有效投注",
        text143: "总派金",
        text144: "总盈亏金额",
        // 彩金
        text146: "彩金",
        text147: "领取有效期截止",
        text148: "加载中",
        text149: "到底了",
        text150: "暂无数据",
        text151: "彩金领取",
        text152: "流水要求",
        text153: "倍",
        text154: "取消",
        text155: "立即领取",
        text156: "全部",
        text157: "可领取",
        text158: "已领取",
        text159: "已过期",
        // 公告消息
        text160: "公告消息",
        text161: "一条通知都没有呢...",
        text162: "一条公告都没有呢...",
        // 全民返利
        text163: "全民返利",
        text164: "规则说明",
        text165: "累计返利",
        text166: "满",
        text167: "且有效会员≥",
        text168: "人，即可领取",
        text169: "领取",
        text170: "会员（会员总数",
        text171: "有效会员",
        text172: "我的推广码",
        text173: "下载推广码",
        text174: "我的邀请码",
        text175: "复制邀请码",
        text176: "我的推广地址",
        text177: "复制地址",
        text178: "规则说明",
        text179: "领取返利",
        text180: "流水要求",
        text181: "取 消",
        text182: "确 定",
        text183: "未满足领取要求，最低领取金额",
        text184: "有效会员人数≥",
        text185: "人,领取上限",
        text186: "领取成功",
        text187: "领取失败",
        // 邀请会员
        text188: "邀请会员",
        text189: "总投注",
        text190: "累计获得总返利",
        text191: "注册时间：",
        text192: "选择时间：",
        text193: "会员账号",
        text194: "注册时间",
        text195: "总有效投注",
        text196: "返利金",
        // 个人资料
        text197: "个人资料",
        text198: "常用设备管理",
        text199: "（最多可绑定10个常用登陆设备）",
        text200: "本机",
        text201: "删除",
        text202: "暂无设备",
        // 资金密码
        text203: "资金密码",
        text204: "旧密码",
        text205: "请输入原资金密码",
        text206: "当前您所使用的资金密码",
        text207: "新密码",
        text208: "请输入新资金密码",
        text209: "密码6位数字组合",
        text210: "确认密码",
        text211: "请再次输入密码",
        text212: "必须与新密码一致",
        text213: "确定",
        text214: "确认密码不能为空",
        text215: "交易密码必须为6位数字",
        text216: "两次输入的密码不一致",
        text217: "新资金密码不能为空",
        text218: "交易密码必须为6位数字",
        text219: "原资金密码不能为空",
        // 账号密码
        text220: "账号密码",
        text221: "旧密码",
        text222: "请输入原登录密码",
        text223: "当前您所使用的登录密码",
        text224: "新密码",
        text225: "请输入新登录密码",
        text226: "密码6-15位,字母数字符号至少两种组合",
        text227: "确认密码",
        text228: "请再次输入新密码",
        text229: "必须与新密码一致",
        text230: "确认密码不能为空",
        text231: "两次输入的密码不一致",
        text232: "原登录密码不能为空",
        text233: "新登录密码不能为空",
        text234: "请重新登录",
        // tab
        text235: "安全验证",
        text236: "资金密码",
        text237: "登录密码",
        text238: "设备管理",
        // 返水详情页
        text239: "交易记录",
        text240: "返水详情",
        text241: "只可查询近一个月的交易记录，如需更多信息请联系客服查询",
        text242: "投注金额",
        text243: "时间筛选",
        text244: "时间",
        text245: "游戏平台",
        text246: "游戏名称",
        text247: "流水",
        text248: "返水",
        text249: "您查询时间已超过既定期限，如需更多信息请联系在线客服",
        // 交易记录
        text250: "交易记录",
        text251: "只可查询近一个月的交易记录，如需更多信息请联系客服查询",
        text252: "交易类型",
        text253: "交易时间",
        text254: "选择时间",
        text255: "复制",
        text256: "金额",
        text257: "状态",
        text258: "详情",
        text259: "充值记录",
        text260: "银行转账",
        text261: "提款记录",
        text262: "返水记录",
        text263: "其它",
        text264: "今日",
        text265: "昨日",
        text266: "近7日",
        text267: "近30日",
        text268: "支付方式",
        text269: "提款方式",
        text270: "返水类型",
        text271: "类型",
        text272: "您查询时间已超过既定期限，如需更多信息请联系在线客服",
        // 收款方式管理
        text273: "收款方式管理",
        text274: "添加银行卡/数字货币",
        text275: "不可重复添加相同的银行",
        // 基本信息
        text276: "账号密码",
        text277: "交易密码",
        text278: "管理设备",
        text279: "管理",
        text280: "修改",
        text281: "设置",
        // 信息编辑
        text282: "真实姓名",
        text283: "请输入真实姓名",
        text284: "会员生日",
        text285: "用户昵称",
        text286: "联系QQ",
        text287: "请输入用户昵称",
        text288: "手机号码",
        text289: "请输入手机号码",
        text290: "联系微信",
        text291: "请输入联系微信",
        text292: "会员邮箱",
        text293: "请输入会员邮箱",
        text294: "请输入用户昵称",
        // 个人中心
        text295: "普通用户",
        text296: "余额",
        text297: "佣金",
        text298: "返水领取",
        text299: "VIP等级",
        text300: "等级",
        text301: "达成条件",
        text302: "次",
        // 用户菜单
        text303: "个人资料",
        text304: "游戏记录",
        text305: "交易记录",
        text306: "公告消息",
        text307: "收款方式管理",
        text308: "活动记录",
        text309: "全民返利",
        text310: "存款",
        text311: "取款",
        text312: "彩金",
        text313: "返水额度不满足条件，无法领取！",
        text314: "请输入最低金额",
        text315: "彩金已领取过了",
        text316: "已过期，无法领取",
    },
    ...comp_zh_CN,
};
export default zh_CN